import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import BasicRating from "./Pages/Rating";
import "./App.css";

import NotFoundPage from "./Pages/NotFoundPage";

import "animate.css";

import Share from "./Pages/Share";

import ThankYou from "./Pages/ThankYou";
import { AnimatePresence } from "framer-motion";
import AnimationPage from "./Pages/AnimationPage";
import OptOut from "./Pages/OptOut";
import Faq from "./Pages/Faq";
import NewRating from "./Pages/NewRating";
import Login from "./Pages/Login";
import CCorders from "./Pages/CCorders";
import { SocketContext, socket } from "./context/socketContext";
import { useAuthState } from "./context/context";
import ProtectedRoute from "./ProtectedRoute";

function AnimatedRoutes() {
  const location = useLocation();

  const userDetails = useAuthState();

  return (
    <AnimatePresence>
      <SocketContext.Provider value={socket}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<Login />} />

          <Route
            element={
              <ProtectedRoute userDetails={userDetails} isAdmin={true} />
            }
          >
            <Route path="/orders" element={<CCorders socket={socket} />} />
          </Route>

          {/* <Route path="/:platformName" element={<NewRating />} />
        <Route path="/:platformName/:restId" element={<NewRating />} /> */}
          <Route
            path="/:platformName/:restId/:orderNo"
            element={<NewRating />}
          />
          <Route path="/optIn" element={<AnimationPage />} />
          <Route path="/optOut" element={<OptOut />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="*" element={<NotFoundPage />} />

          <Route path="/share" element={<Share />} />
          <Route path="/thankYou" element={<ThankYou />} />
        </Routes>
      </SocketContext.Provider>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
