import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { motion } from "framer-motion";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faq = () => {
  const accordionStuff = [
    {
      question: "What are the containers made of?",
      answer: `InfinityBox containers are made of food-grade polypropylene. It is the same material that is used to make Tupperware containers that we have been using at home for decades. 

      Reusable containers are the most environment friendly containers and emit 10 times lesser greenhouse gas emissions at no extra cost compared to any other container including biodegradable ones.`,
    },
    {
      question: "Who is going to clean the containers?",
      answer: `InfinityBox has a cleaning facility in Bangalore which is responsible for the hygiene management of the containers. All the containers would be cleaned following a lab-certified process and checked against our proprietary CTQs. Only once each container meets the criteria, will it be returned to the partner restaurants.`,
    },

    {
      question:
        "What are the changes required from a customer to opt-in for an InfinityBox container?",
      answer: `The only change required other than eating in a Tupperware quality, microwaveable container, is to return the containers to the collection executive who’ll come to pick the container up from your house. You are not required to clean it, but if you want to give it a quick wash, please go ahead!`,
    },
    {
      question:
        "What is the environmental impact of these containers/of reuse? (Why plastic?)",
      answer: `Each container used 40 times will emit 10x less greenhouse gases (Co2, etc.) into the atmosphere compared to any other single-use item (paper, cardboard, etc.). This is because there are resources required to make paper-based packaging (trees cut down, excessive water usage, chemicals, etc.) and we tend to ignore that part of the supply chain.

      Moreover, the commonly available bagasse etc. containers in the market have a thin plastic lining which is not really visible to the naked human eye. These linings are necessary to hold Indian food (Curries, etc.) which would otherwise get absorbed into the container. But these linings also make the container non-recyclable and non-biodegradable. They leave behind microplastics!`,
    },
    {
      question: "Can I buy it?",
      answer: `At the moment, no. We are only offering it as a reuse solution to save the environment.`,
    },
    {
      question: "Why am I still being charged a packaging charge?",
      answer: `We are providing food in better Tupperware quality containers that are microwaveable and leakproof, and also bear the cost of collection and washing. To be able to provide you this environment-friendly service, we are not charging anything extra, but can't remove the packaging charges.`,
    },
    {
      question: "Do I have to clean it?",
      answer: `You are not required to clean it. We have a lab certified cleaning process and will use that. So no extra work for you!`,
    },
    {
      question:
        "Can I leave it with the guard? Reception? If I am not at home what happens?",
      answer: `Yes, definitely. Just let the delivery guy who will call you when he is coming to collect the containers know where the container is and he will collect it.`,
    },
    {
      question: "Can I reschedule?",
      answer: `Yes, of course. Please just let the delivery guy who will call you when he is coming to collect the container, know when to come.`,
    },
    {
      question: "Is there a penalty if I lose it?",
      answer: `No, there is no penalty.`,
    },
    {
      question:
        "What are the changes required from a customer to opt-in for a reusable container?",
      answer: `The only change is to return the containers to the collection executive who will come to pick the containers up from your house.`,
    },
    {
      question: "Are these containers recyclable?",
      answer: `Yes, 100%. We also have partnerships with the largest recyclers in the country to take care of that.`,
    },
  ];
  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div
          style={{
            display: "flex",
            margin: "0 auto",
            overflow: "hidden",

            alignItems: "center",

            width: "100%",
            maxWidth: "700px",
            flexDirection: "column",

            paddingTop: "1em",
          }}
        >
          <Box style={{ flex: 2, marginBottom: "1vh" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  height: "12vh",

                  position: "relative",
                  overflow: "hidden",
                }}
                src={"/assets/campaign_logo.png"}
              ></img>
              {/* <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.5rem",
                  marginLeft: "1vh",
                }}
              >
                &
              </Typography>
              <img
                style={{ width: "45px", height: "45px" }}
                src={"/assets/swiggy.png"}
              ></img> */}
            </div>
          </Box>
          <Box style={{ flex: 2, marginBottom: "1vh" }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                maxWidth: 300,
                marginTop: "2vh",
                fontWeight: "bold",
                color: "#00750F",

                fontSize: "15px",
              }}
            >
              Frequently Asked Questions (FAQ)
            </Typography>
          </Box>
          <div style={{ width: "300px", marginTop: "2vh" }}>
            {accordionStuff.map((accordion, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      margin: "auto",
                      fontSize: "0.8rem",
                      textAlign: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {accordion.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "#ECF3ED" }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "0.8rem",
                    }}
                  >
                    {accordion.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    margin: "auto",
                    fontSize: "0.8rem",
                    textAlign: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  What lab certifications do you have?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "#ECF3ED" }}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "0.8rem",
                  }}
                >
                  InfinityBox containers are frequently tested by third-party
                  labs for: <br />
                  <li>
                    Migration testing – to check if plastic is leaching onto the
                    food. We have successfully passed this test several times.
                    We land at around 20 when the acceptable limit is around
                    50-60.
                  </li>
                  <li>
                    Anti-microbial testing - This is to test if there is any
                    bacteria or other microorganism on our container post
                    washing. Our containers after 200 washes had a reading of
                    340 when the acceptable limit for food items is 1,00,000.
                  </li>
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: 300,
              position: "relative",
              marginTop: "2em",

              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography style={{ fontFamily: "Poppins", fontSize: "0.9rem" }}>
              Mission By
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "105px", height: "45px" }}
                src={"/assets/Infinity_logo_black.png"}
              ></img>
              {/* <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.5rem",
                  marginLeft: "1vh",
                }}
              >
                &
              </Typography>
              <img
                style={{ width: "45px", height: "45px" }}
                src={"/assets/swiggy.png"}
              ></img> */}
            </div>
          </Box>

          <Typography
            style={{
              fontFamily: "Poppins",
              justifyContent: "center",
              margin: "auto",
              marginBlock: "1vh",

              width: 300,

              flex: 1,

              position: "relative",

              fontSize: "0.4rem",
            }}
          >
            © InfinityBox Private Limited 2022. <br /> By opting in, you agree
            to share your information with InfinityBox.
          </Typography>
        </div>
      </motion.div>
    </div>
  );
};

export default Faq;
