import styled from "styled-components";
import ShareIcon from "@material-ui/icons/Share";
import { useState } from "react";
import {
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
    FacebookShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
const title = "#DitchThemDisposables";
const shareUrl = `${window.location.origin}/share`;
const source = `${window.location.origin}/assets/card.png`;

export const socials = [
    {
        icon: <FacebookShareButton
            url={shareUrl}
            hashtag={title}
        >
            <FacebookIcon size={32} round />
        </FacebookShareButton>
    },
    {
        icon: <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=" "
        >
            <WhatsappIcon size={32} round />
        </WhatsappShareButton>
    },
    {
        icon: <LinkedinShareButton
            url={source}
            summary={title}
            source={shareUrl}
            title="Infinity Box"
        >
            <LinkedinIcon size={32} round />
        </LinkedinShareButton>
    },
    {
        icon: <TwitterShareButton
            url={shareUrl}
            title="Infinity Box"
            hashtags={[title]}
        >
            <TwitterIcon size={32} round />
        </TwitterShareButton>
    }


];

export default function OptinSocialShare() {
    const [menuActive, setMenuActive] = useState(false);
    const handleToggleMenu = () => {
        setMenuActive((menuActive) => !menuActive);
    };

    const socialLinks = socials.map((social, index) => {
        return (
            <SocialLink
                as="a"
                href="#"
                target="_blank"
                rel="noreferrer"
                role="button"
                isActive={menuActive}
                position={index}
                key={index}
                className={menuActive ? 'showSocialIcon' : 'hideSocialIcon'}
            >
                {social.icon}
            </SocialLink>
        );
    });

    return (
        <ShareContainer>
            <ShareButton
                isActive={menuActive}
                aria-label="Share Button"
                aria-expanded={menuActive}
                role="button"
                background="#242424"
                color="white"
                onClick={handleToggleMenu}
            >
                <ShareIcon />
            </ShareButton>
            {socialLinks}
        </ShareContainer>
    );
}

const ShareContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ShareButton = styled.button`
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius:5rem;
  outline: none;
  border: 2px solid ${({ background }) => background};
  padding: 8px;
  transform: ${({ isActive }) => (isActive ? "scale(0.8)" : "scale(1.0)")};
  transition: all 0.2s, transform 0.2s 0.2s;
  :hover {
    background: white;
    color: ${({ background }) => background};
  }
  height: 3.1rem;
  width: 6rem;
  margin-right: 10px;
  padding: 10px;
`;

const SocialLink = styled(ShareButton)`
  position: absolute;
  padding:0;
  border:none;
  width: 50px;
  z-index: 0;
  transform: none;
  margin-right:20px;
  transition: top 0.2s ${({ position }) => `${position * 50}ms`},
    left 0.2s ${({ position }) => `${position * 50}ms`};
  left: ${({ isActive, position }) =>
        isActive ? `${(-1) ** position * Math.ceil(position / 2) * 40}px` : "0"};
  top: ${({ isActive }) => (isActive ? `60px` : "25px")};
  box-shadow: ${({ isActive }) => (isActive ? `0` : `0`)};
`;
