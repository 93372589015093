// import { baseURL } from "../config/config";

export const loginUser = async (dispatch, loginPayload) => {
  try {
    dispatch({ type: "REQUEST_LOGIN" });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loginPayload),
    };

    let response = await fetch("url" + `login`, requestOptions);

    let data = await response.json();

    if (data.error === "") {
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      localStorage.setItem("currentOptInUser", JSON.stringify(data));
      return data;
    }
    dispatch({ type: "LOGIN_ERROR", error: data.error });
    throw data.error;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error });
    throw error;
  }
};

export const logout = async (dispatch) => {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("currentOptInUser");
  localStorage.removeItem("optInAccessToken");
};
