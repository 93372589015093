import { Box, Button, Typography } from "@mui/material";
import React, { useRef } from "react";
// import card from "../assets/card.png";
import { Icon } from "@iconify/react";
import { toBlob } from "html-to-image";
import { saveAs } from "file-saver";
// import exportAsImage from "imageDownload/exportAsImage";
import { useNavigate } from "react-router-dom";
import OptinSocialShare from "./common/OptinSocialShare";

function Share() {
  let navigate = useNavigate();

  const imageRef = useRef();

  const handleDone = () => {
    // const dispatch = AppDispatch();
    // dispatch(
    //   counterSlice.actions.feedbackStatusLoad({
    //     loader: false,
    //     rating: false,
    //     animation: false,
    //     share: false,
    //     thankYou: true,
    //   })
    // );
    navigate(`/ThankYou`);
  };

  const handleDownload = () => {
    saveAs("/assets/card.png", "card.jpg");
  };

  // const handleShare = async (e) => {
  //   const newFile = await toPng(exportRef.current);
  //   console.log(
  //     "local",
  //     await fetch(`./${characterArray[characterNo - 1].name}.gif`)
  //   );
  //   console.log(
  //     "TYPE",
  //     newFile.type,
  //     `${characterArray[characterNo - 1].name}.gif`,
  //     exportRef.current
  //   );
  //   const data = {
  //     files: [
  //       new File([newFile], `${characterArray[characterNo - 1].name}.gif`, {
  //         type: newFile.type,
  //       }),
  //     ],
  //     title: `${characterArray[characterNo - 1].name}`,
  //     text: `I opted in for InfinityBox on Swiggy and saved a friend!
  //           #DitchThemDisposables #InfinityBox #PlasticPollution`,
  //   };

  //   try {
  //     if (!navigator.canShare(data)) {
  //       e.target.style.background = "";
  //       e.target.style.backgroundColor = "#1BC831";
  //       console.error("Can't share");
  //     }
  //     // if (navigator.canShare && navigator.canShare({files: data.files})) {
  //     //     navigator.share({
  //     //         files: data.files,
  //     //         title: `${characterArray[characterNo - 1].name}`,
  //     //         text: `I opted in for InfinityBox on Swiggy and saved a friend!
  //     // #DitchThemDisposables #InfinityBox #PlasticPollution`,
  //     //         })
  //     //         .then(() => console.log('Share was successful.'))
  //     //         .catch((error) => console.log('Sharing failed', error));
  //     // }
  //     await navigator.share(data);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const handleShare = async () => {
    const newFile = await fetch("/assets/card.png").then((r) => r.blob());
    // const data = {
    //   files: [
    //     new File([newFile], `card.png`, {
    //       type: "image/gif",
    //     }),
    //   ],
    //   title: "DitchThemDisposables",
    //   text: `I opted in for InfinityBox on Swiggy and saved a friend! #DitchThemDisposables #InfinityBox #PlasticPollution`,
    let file = new File([newFile], "card.png", {
      type: "image/jpeg",
    });
    let files = [file];

    if (navigator.canShare && navigator.canShare({ files: files })) {
      navigator
        .share({
          title: "#DitchThemDisposables",
          // text: `I opted in for InfinityBox on Swiggy and saved a friend! #DitchThemDisposables #InfinityBox #PlasticPollution`,

          files: files,
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      window.alert("Share not supported");
    }

    // try {
    //   if (!navigator.canShare(data)) {
    //     console.error("Can't share");
    //   }
    //   await navigator.share(data);
    // } catch (err) {
    //   console.error(err);
    // }
  };

  return (
    // <motion.div
    //   initial={{ opacity: 0, x: 100 }}
    //   animate={{ opacity: 1, x: 0 }}
    //   exit={{ opacity: 0 }}
    // >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        // border: "3px solid pink",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          margin: "0 auto",
          overflow: "hidden",

          alignItems: "center",

          width: "100%",
          maxWidth: "700px",
          flexDirection: "column",

          paddingTop: "2em",
        }}
      >
        <img
          style={{
            height: "55vh",
            width: "35vh",
            maxWidth: "70vh",
            padding: "1rem",
            zIndex: 2,
            position: "absolute",
          }}
          src={"/assets/cardShine.gif"}
        />
        <div
          id="sticker"
          ref={imageRef}
          style={{
            display: "flex",
            margin: "0 auto",
            overflow: "hidden",

            alignItems: "center",

            width: "100%",
            maxWidth: "700px",
            flexDirection: "column",
          }}
        >
          <img
            style={{
              boxShadow: 3,

              height: "55vh",
              width: "35vh",
              maxWidth: "70vh",
              padding: "1rem",
              zIndex: 1,
              position: "absolute",
            }}
            src={"/assets/card.png"}
            alt="image"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            zIndex: 3,
            position: "absolute",
            top: "65vh",
            width: 300,

            alignItems: "space-evenly",
            justifyContent: "space-evenly",
          }}
        >
          {!navigator.canShare ? (
            <OptinSocialShare></OptinSocialShare>
          ) : (
            <Button
              onClick={handleShare}
              sx={{ boxShadow: 3 }}
              style={{
                fontSize: "1.2rem",
                fontWeight: "bolder",
                fontFamily: "Poppins",
                width: "7rem",
                height: "3rem",
                borderRadius: "5rem",
                background: `linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)`,
                color: "white",
                padding: 5,
                position: "relative",

                cursor: "pointer",
                margin: "1rem",
                textAlign: "center",
                textDecoration: "none",
                textTransform: "capitalize",
              }}
            >
              Share
            </Button>
          )}

          <Button
            sx={{ padding: 0, margin: "auto", boxShadow: 3 }}
            style={{
              backgroundColor: "black",
              borderRadius: "10rem",
              height: "3.5rem",
              cursor: "pointer",
              width: "1rem",
            }}
            onClick={handleDownload}
          >
            <Icon
              style={{ fontSize: "2rem", color: "white" }}
              icon="charm:download"
            />
          </Button>
          <Button
            onClick={handleDone}
            sx={{
              boxShadow: 3,
            }}
            style={{
              fontSize: "1.2rem",
              fontFamily: "Poppins",
              fontWeight: "bolder",
              width: "7rem",
              margin: "1rem",
              height: "3rem",
              cursor: "pointer",
              borderRadius: "5rem",
              color: "black",
              padding: 10,
              border: "3px solid #1BC831",

              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            Done
          </Button>
        </div>

        <Box
          style={{
            display: "flex",
            position: "absolute",
            top: "75vh",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBlock: "2rem",
          }}
        >
          <Typography style={{ fontFamily: "Poppins", fontSize: "0.9rem" }}>
            Mission By
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "105px", height: "45px" }}
              src={"/assets/Infinity_logo_black.png"}
            ></img>
            {/* <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "0.5rem",
              marginLeft: "1vh",
            }}
          >
            &
          </Typography>
          <img
            style={{ width: "45px", height: "45px" }}
            src={"/assets/swiggy.png"}
          ></img> */}
          </div>
        </Box>
      </div>
      <div
        style={{
          // border: "1px solid red",
          marginTop: "auto",
          marginBottom: "1vh",
        }}
      >
        <Typography
          style={{
            fontFamily: "Poppins",
            justifyContent: "center",
            margin: "auto",
            // marginTop: "3vh",

            maxWidth: 300,

            position: "relative",

            fontSize: "0.4rem",
          }}
        >
          © InfinityBox Private Limited 2022. <br /> By opting in, you agree to
          share your information with InfinityBox.
        </Typography>
      </div>
    </div>
  );
}

export default Share;
