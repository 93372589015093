import React from "react";
import { Card, Typography, Box } from "@mui/material";
import { motion } from "framer-motion";

const OptOut = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        // border: "3px solid pink",
        minHeight: "100vh",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div
          style={{
            display: "flex",
            margin: "0 auto",
            overflow: "hidden",

            alignItems: "center",

            width: "100%",
            maxWidth: "700px",
            flexDirection: "column",

            paddingTop: "1em",
          }}
        >
          <Box style={{ flex: 2, marginBottom: "1vh", paddingBlock: 10 }}>
            <img
              style={{
                height: "14vh",

                position: "relative",
                overflow: "hidden",
              }}
              src={"/assets/campaign_logo.png"}
            ></img>
          </Box>
          <Card
            elevation={10}
            style={{
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10vh",

              position: "relative",

              maxWidth: 300,

              borderRadius: "5%",
              padding: "1.5rem",
            }}
          >
            <Typography
              style={{
                color: "black",
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "1rem",
                textTransform: "none",
              }}
            >
              We are sorry to hear that. We hope that you will opt-in for
              InfinityBox next time and help us save the environment!
            </Typography>
          </Card>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: 300,
              position: "absolute",
              top: "75%",

              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              marginBlock: "1vh",
            }}
          >
            <Typography style={{ fontFamily: "Poppins", fontSize: "0.9rem" }}>
              Mission By
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "105px", height: "45px" }}
                src={"/assets/Infinity_logo_black.png"}
              ></img>
              {/* <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "0.5rem",
                marginLeft: "1vh",
              }}
            >
              &
            </Typography>
            <img
              style={{ width: "45px", height: "45px" }}
              src={"/assets/swiggy.png"}
            ></img> */}
            </div>
          </Box>
        </div>
      </motion.div>

      <div
        style={{
          // border: "1px solid red",
          marginTop: "auto",
          marginBottom: "1vh",
        }}
      >
        <Typography
          style={{
            fontFamily: "Poppins",
            justifyContent: "center",
            margin: "auto",
            // marginTop: "3vh",

            maxWidth: 300,

            position: "relative",

            fontSize: "0.4rem",
          }}
        >
          © InfinityBox Private Limited 2022. <br /> By opting in, you agree to
          share your information with InfinityBox.
        </Typography>
      </div>
    </div>
  );
};

export default OptOut;
