import axios from "axios";
import { STAGE_V2_API, HOST_API } from "./config";

const axiosInstance = axios.create({
  baseURL: STAGE_V2_API,
  timeout: 1000 * 60 * 5,
  headers: {
    "Content-Type": "application/json",

    Authorization: `Bearer ${localStorage.getItem("optInAccessToken")}`,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("optInAccessToken");

    if (user) {
      config.headers.Authorization = `Bearer ${user}`;
    }

    return config;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

const axiosInstanceV2 = axios.create({
  baseURL: HOST_API,
  timeout: 1000 * 60 * 5,
  headers: { "Content-Type": "application/json" },
});

axiosInstanceV2.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

//     return config;
//   },
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
// );

export default {
  axiosInstance,
  axiosInstanceV2,
};
