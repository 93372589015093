import React from "react";
import "../styles/NotFoundPage.css";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

function ThankYou() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        // border: "3px solid pink",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          margin: "0 auto",
          overflow: "hidden",

          alignItems: "center",

          width: "100%",
          maxWidth: "700px",
          flexDirection: "column",

          paddingTop: "1em",
        }}
      >
        <Box style={{ flex: 1, paddingBlock: 10 }}>
          <img
            style={{
              height: "14vh",

              position: "relative",
              overflow: "hidden",
            }}
            src={"/assets/campaign_logo.png"}
          ></img>
        </Box>

        <Typography
          style={{
            fontFamily: "Poppins",
            marginTop: "2vh",

            maxWidth: 300,
            flex: 1,
            fontWeight: "bolder",
            fontSize: "1.2rem",
          }}
        >
          Changing the earth, <br /> one drop at a time.
        </Typography>
        <div style={{}}>
          <img
            style={{ mixBlendMode: "multiply", height: "25vh" }}
            src={"/assets/drop.gif"}
          ></img>
        </div>

        <div style={{ flex: 1 }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              maxWidth: 300,

              fontWeight: "bolder",
              fontSize: "1.3rem",
            }}
          >
            Thanks for your drop!
          </Typography>
        </div>
        <div style={{ flex: 1 }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              marginTop: "1rem",
              maxWidth: 300,
              fontWeight: "bold",
              fontSize: "0.8rem",
            }}
          >
            To know more about us, please do visit{" "}
            <a href="https://getinfinitybox.com/">InfinityBox.</a>
          </Typography>
        </div>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,

            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBlock: "2rem",
          }}
        >
          <Typography style={{ fontFamily: "Poppins", fontSize: "0.9rem" }}>
            Mission By
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "105px", height: "45px" }}
              src={"/assets/Infinity_logo_black.png"}
            ></img>
            {/* <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "0.5rem",
              marginLeft: "1vh",
            }}
          >
            &
          </Typography>
          <img
            style={{ width: "45px", height: "45px" }}
            src={"/assets/swiggy.png"}
          ></img> */}
          </div>
        </Box>
      </div>
      <div
        style={{
          // border: "1px solid red",
          marginTop: "auto",
          marginBottom: "1vh",
        }}
      >
        <Typography
          style={{
            fontFamily: "Poppins",
            justifyContent: "center",
            margin: "auto",
            // marginTop: "3vh",

            maxWidth: 300,

            position: "relative",

            fontSize: "0.4rem",
          }}
        >
          © InfinityBox Private Limited 2022. <br /> By opting in, you agree to
          share your information with InfinityBox.
        </Typography>
      </div>
    </div>
  );
}

export default ThankYou;
