import React from "react";
import "../styles/NotFoundPage.css";
import { Box, CardContent, Typography } from "@mui/material";
import { motion } from "framer-motion";

function NotFoundPage() {
  return (
    <div
      style={{
        display: "flex",
        margin: "0 auto",
        overflow: "hidden",
        background: "white",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",

        alignItems: "center",

        width: "100%",

        flexDirection: "column",

        paddingTop: "1em",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Box style={{ flex: 2, paddingBlock: 15 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ maxWidth: "120px", maxHeight: "60px" }}
              src={"/assets/Infinity_logo_black.png"}
            ></img>
            {/* <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.5rem",
                  marginLeft: "1vh",
                }}
              >
                &
              </Typography>
              <img
                style={{ width: "45px", height: "45px" }}
                src={"/assets/swiggy.png"}
              ></img> */}
          </div>
        </Box>
        <Box style={{ flex: 2, marginBottom: "1vh" }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              maxWidth: 300,

              margin: "0 auto",
              paddingTop: "2rem",

              fontWeight: "bold",
              color: "black",

              fontSize: "40px",
            }}
          >
            404
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              maxWidth: 300,

              margin: "0 auto",

              fontWeight: "bold",
              color: "black",

              fontSize: "20px",
            }}
          >
            Page Not Found
          </Typography>
        </Box>
        <Box style={{ flex: 2, marginBottom: "1vh" }}>
          <img
            src={"/assets/image 9.svg"}
            style={{ backgroundBlendMode: "overlay", marginTop: "1vh" }}
          />
          <Typography
            sx={{
              marginTop: "4vh",
              fontSize: "16px",
              maxWidth: 300,
              color: "#00750F",
              fontFamily: "Poppins",
              fontWeight: "bolder",
            }}
          >
            Something went wrong. <br />
            <br /> In the meantime, why don't we explore how we can save Mother
            Earth? Visit{" "}
            <a href="https://getinfinitybox.com" target="_blank">
              InfinityBox
            </a>
          </Typography>
        </Box>
      </motion.div>
    </div>
  );
}

export default NotFoundPage;
