import React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function AnimationPage() {
  const navigate = useNavigate();

  const handleClick = () => {
    // const dispatch = AppDispatch();
    // dispatch(
    //   counterSlice.actions.feedbackStatusLoad({
    //     loader: false,
    //     rating: false,
    //     animation: false,
    //     share: true,
    //     thankYou: false,
    //   })
    // );
    navigate("/share");
  };

  return (
    // <motion.div
    //   initial={{ width: 0 }}
    //   animate={{ width: "100%" }}
    //   exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
    // >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        // border: "3px solid pink",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          margin: "0 auto",
          overflow: "hidden",

          alignItems: "center",

          width: "100%",
          maxWidth: "700px",
          flexDirection: "column",

          paddingTop: "1em",
        }}
      >
        <Box style={{ flex: 1, paddingBlock: 10 }}>
          <img
            style={{
              height: "15vh",

              position: "relative",
              overflow: "hidden",
            }}
            src={"/assets/campaign_logo.png"}
          ></img>
        </Box>

        <Typography
          style={{
            fontFamily: "Poppins",
            maxWidth: 300,
            flex: 1,
            fontWeight: "bolder",
            fontSize: "1.2rem",
          }}
        >
          Most disposable plastics end up being tossed into water bodies killing
          countless aquatic wildlife.
        </Typography>
        <div style={{ flex: 1 }}>
          <img
            style={{
              height: "20vh",
              position: "relative",
              overflow: "hidden",
            }}
            src={"/assets/smile_drop.gif"}
            alt="smile_drop"
          />
        </div>

        <div style={{ flex: 1 }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              maxWidth: 300,
              color: "#1BC831",
              fontWeight: "bolder",
              fontSize: "1.2rem",
            }}
          >
            Thanks for reducing that by one and saving a friend.
          </Typography>
          <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
            <Button
              sx={{ boxShadow: 3 }}
              onClick={handleClick}
              style={{
                maxHeight: "3rem",
                backgroundColor: "#1BC831",
                elevation: 10,
                marginTop: "3vh",
                paddingInline: "15",
                borderRadius: "5rem",
                color: "black",
                boxShadow: "10px",
                fontFamily: "Poppins",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                paddingInline: 15,
                textTransform: "none",
              }}
            >
              Meet your friend
            </Button>
          </motion.div>
        </div>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginTop: "4vh",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBlock: "2rem",
          }}
        >
          <Typography style={{ fontFamily: "Poppins", fontSize: "0.9rem" }}>
            Mission By
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "105px", height: "45px" }}
              src={"/assets/Infinity_logo_black.png"}
            ></img>
            {/* <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "0.5rem",
              marginLeft: "1vh",
            }}
          >
            &
          </Typography>
          <img
            style={{ width: "45px", height: "45px" }}
            src={"/assets/swiggy.png"}
          ></img> */}
          </div>
        </Box>
      </div>

      <div
        style={{
          // border: "1px solid red",
          marginTop: "auto",
          marginBottom: "1vh",
        }}
      >
        <Typography
          style={{
            fontFamily: "Poppins",
            justifyContent: "center",
            margin: "auto",
            // marginTop: "3vh",

            maxWidth: 300,

            position: "relative",

            fontSize: "0.4rem",
          }}
        >
          © InfinityBox Private Limited 2022. <br /> By opting in, you agree to
          share your information with InfinityBox.
        </Typography>
      </div>
    </div>
  );
}

export default AnimationPage;
