import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
  Alert,
  Box,
  CircularProgress,
  TableRow,
} from "@mui/material";

import { getRMlist } from "../api";
import { useSnackbar } from "notistack";
import Header from "./common/Header";
import { ordersList } from "./../api";

const CCorders = (props) => {
  // Messages States
  const [tableData, setTableData] = useState([]);

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let orders = await ordersList();
      // console.log("optin orders", orders);

      if (orders.status === "Success") {
        setTableData(orders.data);
      } else if (orders.status == 401) {
        enqueueSnackbar(orders.message, {
          variant: "error",
        });
      } else if (orders.status == 500) {
        enqueueSnackbar("Unable to fetch orders", {
          variant: "error",
        });
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    props.socket.on("optin_cc_table", (data) => {
      // console.log("data on arrival", data);

      setTableData([...data, ...tableData]);
    });
  }, [tableData]);

  return (
    <div>
      <Header socket={props.socket} />
      {!loading ? (
        <>
          {tableData.length !== 0 ? (
            <Paper
              sx={{ maxWidth: "100vw", overflow: "hidden", margin: "0 auto" }}
            >
              <TableContainer sx={{ maxWidth: "100%", margin: "0 auto" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontFamily: "Poppins", fontWeight: "bolder" }}
                      >
                        Sl no.
                      </TableCell>
                      <TableCell
                        sx={{ fontFamily: "Poppins", fontWeight: "bolder" }}
                      >
                        Order ID
                      </TableCell>
                      <TableCell
                        sx={{ fontFamily: "Poppins", fontWeight: "bolder" }}
                      >
                        Restaurant Name
                      </TableCell>
                      <TableCell
                        sx={{ fontFamily: "Poppins", fontWeight: "bolder" }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData &&
                      tableData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ fontFamily: "Poppins" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell sx={{ fontFamily: "Poppins" }}>
                            {row.order_no}
                          </TableCell>
                          <TableCell sx={{ fontFamily: "Poppins" }}>
                            {row.restaurant_name}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "Poppins",
                              color:
                                row.status === "No" ||
                                row.status === "Always No"
                                  ? "red"
                                  : "green",
                            }}
                          >
                            {row.status}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
            </Paper>
          ) : (
            <Alert severity="info">No orders to display</Alert>
          )}
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            value={50}
            size={50}
            thickness={4}
            sx={{ position: "absolute", top: "50%", left: "45%" }}
            disableShrink
          />
        </Box>
      )}
    </div>
  );
};

export default CCorders;
