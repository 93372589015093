import React, { useEffect, useState } from "react";

import { useNavigate, useParams, Link } from "react-router-dom";

import {
  CircularProgress,
  Modal,
  Fade,
  Dialog,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Box,
  Typography,
  Backdrop,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import ReactConfetti from "react-confetti";
import { orderOptIn } from "../api";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import useWindowDimension from "./../hooks/useWindowDimension";
import axios from "axios";
import ReactGA from "react-ga4";

const useStyles = styled((theme) => ({
  root: {
    height: ({ height }) => height,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewRating = () => {
  const navigate = useNavigate();

  const [clicked, setClicked] = useState(false);

  const [ip, setIP] = useState("");

  const { width, height } = useWindowDimension();

  const classes = useStyles({ height });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 290,
    bgcolor: "background.paper",

    borderRadius: "5%",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      // console.log(res.data);
      setIP(res.data.ip);
    };

    getData();
  }, []);

  const { platformName, restId, orderNo } = useParams();

  const [open, setOpen] = useState(false);

  const [optFail, setOptFail] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOptFail = () => setOptFail(false);

  const handleOut = async () => {
    setOpen(false);
    let optin = await orderOptIn(
      platformName,
      orderNo ? orderNo : 0,
      0,
      restId ? restId : 0,
      ip ? ip : 0
    );

    ReactGA.event({
      category: "User Opt-out",
      action: "0",

      value: orderNo, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });

    navigate("/optOut", { replace: true });
  };

  const handleClick = async () => {
    setClicked(!clicked);

    let optin = await orderOptIn(
      platformName,
      orderNo ? orderNo : 0,
      1,
      restId ? restId : 0,
      ip ? ip : 0
    );
    // console.log("OPTED in", optin);

    ReactGA.event({
      category: "User Optin",
      action: "1",

      value: orderNo, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });

    setTimeout(() => {
      if (optin.status == "Success") {
        navigate("/optIn", { replace: true });
      } else {
        setClicked(false);
        setOptFail(true);
      }
    }, 2000);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        // border: "3px solid pink",
        minHeight: `${height}px`,
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div
          style={{
            display: "flex",
            margin: "auto",
            overflow: "hidden",

            width: "100%",
            maxWidth: "700px",
            flexDirection: "column",

            paddingTop: "1em",
          }}
        >
          {clicked && (
            <ReactConfetti width={width} height={height} tweenDuration={200} />
          )}
          <Box style={{ flex: 2, marginBottom: "1vh" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ maxWidth: "100px", maxHeight: "40px" }}
                src={"/assets/Infinity_logo_black.png"}
              ></img>
              {/* <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.5rem",
                  marginLeft: "1vh",
                }}
              >
                &
              </Typography>
              <img
                style={{ width: "45px", height: "45px" }}
                src={"/assets/swiggy.png"}
              ></img> */}
            </div>
          </Box>
          <Box style={{ flex: 2, marginBottom: "1vh" }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                maxWidth: 300,

                margin: "0 auto",
                paddingTop: "1rem",

                fontWeight: "bold",
                color: "#00750F",

                fontSize: "15px",
              }}
            >
              Would you like to opt-in for InfinityBox & save the environment?
            </Typography>
          </Box>
          <Box style={{ flex: 1 }}>
            <Typography
              sx={{
                marginTop: "1vh",
                fontSize: "11px",
                color: "grey",

                fontFamily: "Poppins",
                fontWeight: "bolder",
              }}
            >
              Order Number: {orderNo}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              justifySelf: "center",
              alignItems: "center",
              justifyContent: "space-between",
              flex: 4,
              marginTop: "3vh",
              border: "none",

              height: "100px",

              width: "80%",
              maxWidth: "700px",
            }}
          >
            <AliceCarousel
              autoPlay
              infinite
              autoPlayInterval="2500"
              autoPlayDirection="ltr"
              disableButtonsControls
            >
              <div className="item" data-value="1">
                <img src={"/assets/Group 525.svg"} className="sliderimg" />
                <Typography
                  sx={{
                    marginTop: "1vh",
                    fontSize: "16px",
                    color: "#00750F",
                    fontFamily: "Poppins",
                    fontWeight: "bolder",
                  }}
                >
                  Opt-in at No Extra Cost!
                </Typography>
                <Typography
                  sx={{
                    marginTop: "1vh",
                    fontSize: "11.55px",
                    fontFamily: "Poppins",
                  }}
                >
                  Click on the opt-in button to get food in an InfinityBox at no
                  extra cost!
                </Typography>
              </div>
              <div className="item" data-value="2">
                <img src={"/assets/Group 526.svg"} className="sliderimg" />
                <Typography
                  sx={{
                    marginTop: "1vh",
                    fontSize: "16px",

                    color: "#00750F",
                    fontFamily: "Poppins",
                    fontWeight: "bolder",
                  }}
                >
                  Food in a Tupperware quality container
                </Typography>
                <Typography
                  sx={{
                    marginTop: "1vh",
                    fontSize: "11.55px",
                    fontFamily: "Poppins",
                  }}
                >
                  InfinityBox offers microwaveable, leak-proof and premium
                  quality containers. They are also environment friendly!
                </Typography>
              </div>
              <div className="item" data-value="3">
                <img src={"/assets/Group 527.svg"} className="sliderimg" />
                <Typography
                  sx={{
                    marginTop: "1vh",
                    fontSize: "16px",
                    color: "#00750F",
                    fontFamily: "Poppins",
                    fontWeight: "bolder",
                  }}
                >
                  Schedule convenient doorstep pickups
                </Typography>
                <Typography
                  sx={{
                    marginTop: "1vh",
                    fontSize: "11.55px",
                    fontFamily: "Poppins",
                  }}
                >
                  Customers get to choose a convenient slot for pick-up. Our
                  customer care team will reach out for the same.
                </Typography>
              </div>
              <div className="item" data-value="4">
                <img src={"/assets/Ellipse 30.svg"} className="sliderimg" />
                <Typography
                  sx={{
                    marginTop: "1vh",
                    fontSize: "16px",
                    color: "#00750F",
                    fontFamily: "Poppins",
                    fontWeight: "bolder",
                  }}
                >
                  Lab certified cleaning processes
                </Typography>
                <Typography
                  sx={{
                    marginTop: "1vh",
                    fontSize: "11.55px",
                    fontFamily: "Poppins",
                  }}
                >
                  All InfinityBox containers go through a rigorous cleaning
                  process & multiple quality checks before being reused.
                </Typography>
              </div>
            </AliceCarousel>
          </Box>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,

              alignItems: "center",
              justifyContent: "space-between",
            }}
          > */}

          <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
            <Button
              className="button"
              sx={{ boxShadow: 3 }}
              onClick={handleClick}
              style={{
                backgroundColor: "#1BC831",
                marginTop: "0.7rem",
                minWidth: 225,
                height: 40,
                alignSelf: "center",
                borderRadius: "5rem",

                color: "white",
                fontFamily: "Poppins",
                fontWeight: "bold",

                textTransform: "capitalize",
              }}
            >
              {clicked ? (
                <CircularProgress
                  style={{ margin: "0rem 0.95rem" }}
                  color="primary"
                  size="1.5rem"
                />
              ) : (
                "Opt-in"
              )}
            </Button>
          </motion.div>
          <Button
            onClick={handleOpen}
            disabled={clicked ? true : false}
            size="small"
            style={{
              marginTop: "1.5vh",

              alignSelf: "center",
              borderRadius: "5rem",
              fontSize: "0.7rem",

              color: "black",
              fontFamily: "Poppins",

              textTransform: "capitalize",
            }}
          >
            No thanks
          </Button>

          <Typography
            style={{
              fontFamily: "Poppins",
              justifyContent: "center",

              marginTop: "2vh",

              fontWeight: "bold",

              fontSize: "0.6rem",
            }}
          >
            Curious to learn more?{" "}
            <Link to={"/faq"} style={{ textDecoration: "none" }}>
              Click here
            </Link>
          </Typography>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    color: "black",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    textTransform: "none",
                  }}
                >
                  Millions of disposable containers end up in landfills and
                  oceans. You can contribute to a safer environment for our
                  children and animals by opting for InfinityBox.
                  <br />
                  <br />
                  Are you sure you want to opt out?
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    zIndex: 3,

                    alignItems: "space-evenly",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    sx={{ boxShadow: 3 }}
                    onClick={handleOut}
                    style={{
                      backgroundColor: "#AEAEAE",
                      marginTop: "1.5rem",
                      width: "7rem",
                      alignSelf: "center",
                      borderRadius: "5rem",

                      color: "black",
                      fontFamily: "Poppins",
                      fontWeight: 900,
                      fontSize: "1.2rem",
                      textTransform: "capitalize",
                    }}
                  >
                    Yes
                  </Button>

                  <Button
                    sx={{ boxShadow: 3 }}
                    onClick={handleClose}
                    style={{
                      backgroundColor: "#1BC831",

                      marginTop: "1.5rem",
                      marginLeft: "15px",
                      width: "7rem",
                      alignSelf: "center",
                      borderRadius: "5rem",

                      color: "black",
                      fontFamily: "Poppins",
                      fontWeight: 900,
                      fontSize: "1.2rem",
                      textTransform: "none",
                    }}
                  >
                    No
                  </Button>
                </div>
              </Box>
            </Fade>
          </Modal>
          {/* </div> */}
        </div>
      </motion.div>
      <div
        style={{
          // border: "1px solid red",
          marginTop: "auto",
          marginBottom: "1vh",
        }}
      >
        <Typography
          style={{
            fontFamily: "Poppins",
            justifyContent: "center",
            margin: "auto",
            // marginTop: "3vh",

            maxWidth: 300,

            position: "relative",

            fontSize: "0.4rem",
          }}
        >
          © InfinityBox Private Limited 2022. <br /> By opting in, you agree to
          share your information with InfinityBox.
        </Typography>
      </div>

      <Dialog
        open={optFail}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOptFail}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ fontFamily: "Poppins", margin: "2vh", fontWeight: "bold" }}
        >
          Error Opting in
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ fontFamily: "Poppins" }}
            id="alert-dialog-slide-description"
          >
            Something went wrong
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOptFail} sx={{ fontFamily: "Poppins" }}>
            Try again
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewRating;
