import React, { useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CircularProgress,
  Container,
  Typography,
  Box,
  Grid,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Button,
  Avatar,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { postAccessToken } from "./../api";
import { useAuthDispatch } from "../context";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ fontFamily: "Poppins" }}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://getinfinitybox.com/" target="_blank">
        InfinityBox Private Limited
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginButtonProgress, setLoginButtonProgress] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const dispatch = useAuthDispatch();

  const handleUserName = (e) => {
    let strValue = e.target.value.trim();
    setUsername(strValue);
  };

  const handlePassword = (e) => {
    let strValue = e.target.value.trim();
    setPassword(strValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      username === null ||
      username === "" ||
      password === null ||
      password === ""
    ) {
      enqueueSnackbar("Please enter a valid username/password", {
        variant: "error",
      });
      return;
    }

    setLoginButtonProgress(true);

    try {
      const login = await postAccessToken(username, password);
      // console.log("AUTH LOGIN", login);

      if (login.data.access_token !== "") {
        localStorage.setItem(
          "currentOptInUser",
          JSON.stringify(login.data.user)
        );
        localStorage.setItem("optInAccessToken", login.data.access_token);

        //   setSession(dispatch, login.tokens.access.token);

        dispatch({
          type: "LOGIN_SUCCESS",
          payload: login.data,
        });
      } else {
        dispatch({ type: "LOGIN_ERROR", error: "Login error" });
        throw "Login error";
      }
      setLoginButtonProgress(false);
      if (login.data === null) return;
      localStorage.getItem("optInAccessToken") !== "" && navigate("/orders");
      enqueueSnackbar("Logged In Successfully", { variant: "success" });
    } catch (error) {
      setLoginButtonProgress(false);
      enqueueSnackbar("ERROR: " + error, { variant: "error" });
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",

        minHeight: "100vh",
      }}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box style={{ flex: 2, margin: "2vh auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ maxWidth: "120px", maxHeight: "60px" }}
              src={"/assets/Infinity_logo_black.png"}
            ></img>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            marginTop: 7,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#1BC831" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            sx={{ fontFamily: "Poppins", fontWeight: "bolder" }}
          >
            Login
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              onChange={handleUserName}
              margin="normal"
              required
              fullWidth
              value={username}
              helperText={username === "" ? "Username is required." : ""}
              label="Username"
              name="Username"
              autoComplete="Username"
            />
            <TextField
              onChange={handlePassword}
              margin="normal"
              required
              fullWidth
              value={password}
              helperText={password === "" ? "Password is required." : ""}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              sx={{ fontFamily: "Poppins" }}
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              fullWidth
              sx={{ boxShadow: 3 }}
              onClick={handleSubmit}
              style={{
                backgroundColor: "#1BC831",
                marginTop: "0.7rem",

                alignSelf: "center",
                borderRadius: "5rem",

                color: "white",
                fontFamily: "Poppins",
                fontWeight: "bold",

                textTransform: "capitalize",
              }}
            >
              {loginButtonProgress ? (
                <CircularProgress
                  style={{ margin: "0rem 0.95rem" }}
                  color="primary"
                  size="1.5rem"
                />
              ) : (
                "Sign in"
              )}
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </div>
  );
}
