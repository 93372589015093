import axios from "./axios";

const { axiosInstance } = axios;

export const postAccessToken = async (username, password) => {
  try {
    let response = await axiosInstance.post(`oauth/access_token`, {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    console.log("Error while fetching access token", error);
  }
};

export const orderOptIn = async (platformName, orderNo, opted, restId, ip) => {
  try {
    let response = await axiosInstance.post(`orders/optin`, {
      platform_name: platformName,
      restaurant_identifier: restId,
      order_no: orderNo,
      opt_in_status: opted,
      customer_ip: ip,
    });
    return response.data;
  } catch (error) {
    console.log("Error while opt-in", error);
  }
};

export const ordersList = async () => {
  try {
    let response = await axiosInstance.post(`/orders/optin-order-list`);
    return response.data;
  } catch (error) {
    console.log("Error while fetching order list", error);
    if (error.response.status == 401) {
      return {
        message: "Something went wrong. Please login again",
        status: 401,
      };
    } else {
      return { data: null, status: 500 };
    }
  }
};
