import React, { useState } from "react";

import "./App.css";

import NotFoundPage from "./Pages/NotFoundPage";

import "animate.css";

import AnimatedRoutes from "./AnimatedRoutes";

import ReactGA from "react-ga4";
import { SnackbarProvider } from "notistack";

ReactGA.initialize("G-C61QW9WJDP");
ReactGA.send("pageview");

function App() {
  const [errorPage, setErrorPage] = useState(false);

  return (
    <>
      {errorPage && <NotFoundPage />}
      <SnackbarProvider maxSnack={3}>
        <div className="App">
          <AnimatedRoutes />
        </div>
      </SnackbarProvider>
    </>
  );
}

export default App;
